<script setup>

import { defineProps, reactive, onMounted, ref } from "vue"
import { useCourseStore } from "@/store/courses/course"
import { useMemberStore } from "@/store/member"
import { useToast } from "primevue/usetoast"

import Dropdown from 'primevue/dropdown';

const props = defineProps(['member'])
const memberCourses = reactive(props.member.courses)

const courseStore = useCourseStore()
const memberStore = useMemberStore()
const toast = useToast()

const courseId = ref(null)
const expireDate = ref(null)

const courses = ref([])
const loading = ref(true)

const initial = () => {
  loading.value = true

  expireDate.value = new Date()
  expireDate.value.setFullYear(expireDate.value.getFullYear() + 1)

  courseStore.index().then((res) => {
    courses.value = res.data.courses

    loading.value = false
  })
}

const btnAddExpire = () => {
  loading.value = true

  if (! expireDate.value) {
    toast.add({severity:'warn', summary: 'Warning', detail: `Expire date is required`, life: 3000})
    loading.value = false
    return
  }

  memberStore.courseExpire(props.member.id, { course_id: courseId.value, expire_date: expireDate.value })
    .then((response) => {
      if (response.data.error) {
        toast.add({severity:'warn', summary: 'Warning', detail: response.data.error.message, life: 3000})
        return
      }
      
      toast.add({severity:'success', summary: 'Success', detail: `Course added`, life: 3000})
      memberCourses.push({
        id: courseId.value,
        title: courses.value.find((course) => course.id === courseId.value).title,
        pivot: {
          expire_date: new Date(expireDate.value.getTime() - expireDate.value.getTimezoneOffset() * 60000).toISOString().split("T")[0],
          member_id: props.member.id
        }
      })

      courseId.value = null
    })
    .catch((error) => {
      toast.add({severity:'error', summary: 'Error (Catch)', detail: error.message, life: 3000})
    })
    .finally(() => {
      loading.value = false
    })
}

const btnRemoveExpire = (data) => {
  loading.value = true
  memberStore.courseExpire(data.pivot.member_id, { course_id: data.id, expire_date: null })
    .then((response) => {
      if (response.data.error) {
        toast.add({severity:'warn', summary: 'Warning', detail: response.data.error.message, life: 3000})
        return
      }

      toast.add({severity:'success', summary: 'Success', detail: `Course removed`, life: 3000})
      memberCourses.splice(memberCourses.indexOf(data), 1)
    })
    .catch((error) => {
      toast.add({severity:'error', summary: 'Error (Catch)', detail: error.message, life: 3000})
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  initial()
})

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Courses</h5>

        <h6>Add Expire</h6>

        <Dropdown 
          v-model="courseId"

          class="w-full mb-2"
          id="course"
          optionLabel="title"
          :options="courses.filter((course) => ! memberCourses.find((memberCourse) => memberCourse.id === course.id))"
          optionValue="id"
          placeholder="Select a Course"
        >
          <template #option="slotProps">
            <div class="flex align-items-center">
              #{{ slotProps.option.id }} : {{ slotProps.option.title }}
            </div>
          </template>
        </Dropdown>

        <Calendar
          v-model="expireDate"
          id="expire_date"
          
          class="w-full mb-3"
          dateFormat="yy-mm-dd"
          placeholder="Expire Date"
        />

        <Button label="Save" class="w-full" :loading="loading" @click="btnAddExpire" />

        <Divider />

        <DataTable 
          class="p-datatable-gridlines" 
          dataKey="id"
          :lazy="true"
          :loading="loading"
          responsiveLayout="scroll"
          :rowHover="true" 
          :value="memberCourses"
        >
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column field="picture" header="Picture">
            <template #body="{data}">
              <Avatar :image="data.url_picture" size="xlarge" />
            </template>
          </Column>

          <Column field="title" header="Title" />

          <Column field="pivot.expire_date" header="Expire" style="min-width:9rem" />

          <Column header="Options">
            <template #body="{data}">
              <Button 
                icon="pi pi-times" 
                class="p-button-rounded p-button-danger mr-2 mb-2" 
                @click="btnRemoveExpire(data)" 
              />
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>
