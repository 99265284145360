<script setup>

import { defineProps, ref } from "vue"

import { useMemberStore } from "@/store/member"
import { useTagStore } from "@/store/tag"

import { requesting } from "@/scripts/request"

const props = defineProps(['member'])

const memberStore = useMemberStore()
const tagStore = useTagStore()

const selectedTags = ref(props.member.tags)
const tags = ref([])

const disabled = ref(true)
const loading = ref(true)

const save = () => {
	const tags = selectedTags.value.map((tag) => tag.id)

	requesting({
		request: memberStore.updateTags(props.member.id, tags),
		loading,
	})
}

const search = () => {
	loading.value = true

	tagStore.indexAll().then((res) => {
		tags.value = res.data.tags.map((tag) => ({ id: tag.id, name: tag.name }))

		loading.value = false
	})
}

search()

const interval = setInterval(() => {
	if (props.member.tags) {
		selectedTags.value = props.member.tags.map((tag) => ({ id: tag.id, name: tag.name }))
		disabled.value = false
		clearInterval(interval)
	}
}, 100)

</script>

<template>
  <div class="mb-5">
    <div class="text-xl">Tag Member</div>
  </div>

  <label class="block text-900 font-medium mb-2" for="tags">Tags</label>
	<MultiSelect
		v-model="selectedTags"
		class="mb-2 w-full"
		:disabled="disabled"
		filter
		:loading="loading"
		optionLabel="name"
		:options="tags"
	/>

	<br/>

	<Button label="Save" class="w-full" :loading="loading" @click="save" />
</template>
