const afterRequest = (provider, member, model, data) => {
	if (provider == 'axi') {
		model.value = { ...data.axi }
		member.axi_same_names = data.axi_same_names
	} else if (provider == 'exness') {
		model.value = { ...data.exness }
		member.exness_same_client_uids = data.exness_same_client_uids
	} else if (provider == 'fxgt') {
		model.value = { ...data.fxgt }
		member.fxgt_same_client_ids = data.fxgt_same_client_ids
	} else if (provider == 'hfm') {
		model.value = { ...data.hfm }
		member.hfm_same_names = data.hfm_same_names
	} else if (provider == 'xm') {
		model.value = { ...data.xm }
    member.xm_same_client_ids = data.xm_same_client_ids
	}
}

const afterUnlink = (provider, member, model) => {
	if (provider == 'axi') {
		model.value = { _: false }
		member.axi_same_names = []
	} else if (provider == 'exness') {
		model.value = { _: false }
		member.exness_same_client_uids = []
	} else if (provider == 'fxgt') {
		model.value = { _: false }
		member.fxgt_same_client_ids = []
	} else if (provider == 'hfm') {
		model.value = { _: false }
		member.hfm_same_names = []
	} else if (provider == 'xm') {
		model.value = { _: false }
		member.xm_same_client_ids = []
	}
}

const getRequest = (provider, member, client) => {
	if (provider == 'axi')
		return {
			login: client.value,
			member_uid: member.uid,
		}
	else if (provider == 'exness')
		return {
			client_account: client.value,
			member_uid: member.uid,
		}
	else if (provider == 'fxgt' || provider == 'xm')
		return {
			login_id: client.value,
			member_uid: member.uid,
		}
	else if (provider == 'hfm')
		return {
			client_id: client.value,
			member_uid: member.uid,
		}
}

const link = (provider, toast, linking, store, member, client, model) => {
  linking.value = true
  const request = getRequest(provider, member, client)

  store.store(request).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: data.message, life: 5000})

		afterRequest(provider, member, model, data)

  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  }).finally(() => {
    linking.value = false
  })
}

const unlink = (provider, toast, confirm, unlinking, store, member, model) => {
	confirm.require({
		message: `Are you sure you want to unlink ${provider}?`,
		header: 'Confirmation',
		icon: 'pi pi-exclamation-triangle',
		acceptClass: 'p-button-danger',
		rejectClass: 'p-button-secondary',
		accept: () => {
			unlinking.value = true

			store.destroy(model.value.id).then(({ data }) => {
				if (data.error) {
					toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
					return
				}

				toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})

				afterUnlink(provider, member, model)
			}).catch((e) => {
				toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
			}).finally(() => {
				unlinking.value = false
			})
		}
	})
}

const unlinkBan = (provider, toast, confirm, unlinking, configStore, member, model, banId) => {
	confirm.require({
		message: `Are you sure you want to unlink ${provider}?`,
		header: 'Confirmation',
		icon: 'pi pi-exclamation-triangle',
		acceptClass: 'p-button-danger',
		rejectClass: 'p-button-secondary',
		accept: () => {
			unlinking.value = true

			configStore.banListsbanId({ id: banId, provider }).then(({ data }) => {
				if (data.error) {
					toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
					return
				}

				toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})

				afterUnlink(provider, member, model)
			}).catch((e) => {
				toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
			}).finally(() => {
				unlinking.value = false
			})
		}
	})
}

export {
  link,
	unlink,
	unlinkBan,
}
