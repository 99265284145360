<script setup>
  import { computed, defineProps } from 'vue'

	import { toDateTime } from '@/scripts/datetime'

  const props = defineProps(['id', 'modelValue'])

  const modelValue = computed(() => props.modelValue)
</script>

<template>
	<label class="block text-900 font-medium mb-2" for="from_fxgt_id">From Fxgt ID</label>
	<InputText :value="modelValue.from_fxgt_id" id="from_fxgt_id" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="login_id">Login ID</label>
	<InputText :value="modelValue.login_id" id="login_id" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="client_id">Client ID</label>
	<InputText :value="modelValue.client_id" id="client_id" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="server_id">Server ID</label>
	<InputText :value="modelValue.server_id" id="server_id" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="platform">Platform</label>
	<InputText :value="modelValue.platform" id="platform" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="referral_code">Referral Code</label>
	<InputText :value="modelValue.referral_code" id="referral_code" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="country_name">Country Name</label>
	<InputText :value="modelValue.country_name" id="country_name" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="currency_name">Currency Name</label>
	<InputText :value="modelValue.currency_name" id="currency_name" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="account_type_category_name">Account Type Category Name</label>
	<InputText :value="modelValue.account_type_category_name" id="account_type_category_name" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="referred_by">Referred By</label>
	<InputText :value="modelValue.referred_by" id="referred_by" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="created_on">Created On</label>
	<InputText :value="modelValue.created_on" id="created_on" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="affiliation_status">Affiliation Status</label>
	<InputText :value="modelValue.affiliation_status" id="affiliation_status" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="ftd_date">Ftd Date</label>
	<InputText :value="modelValue.ftd_date" id="ftd_date" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="ftd_amount_usd">Ftd Amount Usd</label>
	<InputText :value="modelValue.ftd_amount_usd" id="ftd_amount_usd" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="first_trade">First Trade</label>
	<InputText :value="modelValue.first_trade" id="first_trade" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="last_trade">Last Trade</label>
	<InputText :value="modelValue.last_trade" id="last_trade" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="activation_date">Activation Date</label>
	<InputText :value="modelValue.activation_date" id="activation_date" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="deposits_usd">Deposits Usd</label>
	<InputText :value="modelValue.deposits_usd" id="deposits_usd" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="funds_out_usd">Funds Out Usd</label>
	<InputText :value="modelValue.funds_out_usd" id="funds_out_usd" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="status">Status</label>
	<InputText :value="modelValue.status" id="status" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="equity">Equity</label>
	<InputText :value="modelValue.equity" id="equity" type="text" class="w-full mb-3" readonly />

	<label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
  <InputText :value="toDateTime(modelValue.created_at)" id="created_at" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
  <InputText :value="toDateTime(modelValue.updated_at)" id="updated_at" type="text" class="w-full mb-3" readonly />
</template>
