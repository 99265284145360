<script setup>

import { defineProps, ref } from "vue"

const props = defineProps(['member'])

const expandedRows = ref([])

</script>

<template>
	<div class="mb-5">
		<div class="text-xl">Level</div>
	</div>

	<DataTable
		v-model:expandedRows="expandedRows"

		class="p-datatable-gridlines"
		dataKey="id"
		responsiveLayout="scroll"
		:value="props.member.member_levels"
	>
		<template #empty>
			No records.
		</template>
		<template #expansion="slotProps">
			<div class="p-3">
				<DataTable :value="slotProps.data.starts">
					<Column header="Account ID">
						<template #body="{data}">
							{{ data.account_id }}
							<Tag rounded :value="props.member.member_level_accounts.find(x => x.id === data.account_id)?.type ?? '-'" />
						</template>
					</Column>

					<Column header="Starts">
						<template #body="{index}">
							{{ slotProps.data.starts[index].commission }}
						</template>
					</Column>

					<Column header="Currents">
						<template #body="{index}">
							{{ slotProps.data.currents[index].commission }}
						</template>
					</Column>

					<Column header="Different">
						<template #body="{index}">
							{{ slotProps.data.currents[index].commission - slotProps.data.starts[index].commission }}
						</template>
					</Column>
				</DataTable>
			</div>
		</template>

		<Column expander />

		<Column header="Year/Month">
			<template #body="{data}">
				{{ data.year }} / {{ data.month }}
			</template>
		</Column>

		<Column field="different" header="Different" />

	</DataTable>
</template>
