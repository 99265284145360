<script setup>

import { onMounted, reactive, ref } from "vue"
import { useAuthStore } from "@/store/auth"
import { useAxiStore } from "@/store/axi"
import { useConfigStore } from "@/store/config"
import { useExnessStore } from "@/store/exness"
import { useFxgtStore } from "@/store/fxgt"
import { useHfmStore } from "@/store/hfm"
import { useMemberStore } from "@/store/member"
import { useXmStore } from "@/store/xm"

import { useConfirm } from "primevue/useconfirm"
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"

import provinces from "@/../../configs/location_th/provinces"
import districts from "@/../../configs/location_th/districts"
import subdistricts from "@/../../configs/location_th/subdistricts"

import FormAxi from "@/components-uhas/forms/FormAxi.vue"
import FormExness from "@/components-uhas/forms/FormExness.vue"
import FormFxgt from "@/components-uhas/forms/FormFxgt.vue"
import FormHfm from "@/components-uhas/forms/FormHfm.vue"
import FormXm from "@/components-uhas/forms/FormXm.vue"

import MemberCourseExpire from "@/components-uhas/members/MemberCourseExpire.vue"
import MemberLevelTag from "@/components-uhas/members/MemberLevelTag.vue"
import MemberProviderConnection from "@/components-uhas/members/MemberProviderConnection.vue"
import UtilityButtonBack from "@/components-uhas/utilities/UtilityButtonBack.vue"

import { link, unlink, unlinkBan } from "./index"

import ComponentIB from "./components/ComponentIB.vue"
import ComponentLevel from "./components/ComponentLevel.vue"
import ComponentTagMember from "./components/ComponentTagMember.vue"

const authStore = useAuthStore()
const axiStore = useAxiStore()
const configStore = useConfigStore()
const exnessStore = useExnessStore()
const fxgtStore = useFxgtStore()
const hfmStore = useHfmStore()
const memberStore = useMemberStore()
const xmStore = useXmStore()

const confirm = useConfirm()
const route = useRoute()
const toast = useToast()

const activities = ref([])
const member = reactive({})
const initial = ref(false)

const linkingAxi = ref(false)
const unlinkingAxi = ref(false)

const linkingExness = ref(false)
const unlinkingExness = ref(false)

const linkingFxgt = ref(false)
const unlinkingFxgt = ref(false)

const linkingHfm = ref(false)
const unlinkingHfm = ref(false)

const linkingXm = ref(false)
const unlinkingXm = ref(false)

const openSendMessage = ref(false)
const message = ref('')
const sendingMessage = ref(false)

const axi = ref({ _: false })
const exness = ref({ _: false })
const fxgt = ref({ _: false })
const hfm = ref({ _: false })
const xm = ref({ _: false })

const axiLogin = ref(null)
const exnessClientAccount = ref(null)
const fxgtLoginId = ref(null)
const hfmClientId = ref(null)
const xmLoginId = ref(null)

const loading = ref(false)

const linkAxi = () => link('axi', toast, linkingAxi, axiStore, member, axiLogin, axi)
const linkExness = () => link('exness', toast, linkingExness, exnessStore, member, exnessClientAccount, exness)
const linkFxgt = () => link('fxgt', toast, linkingFxgt, fxgtStore, member, fxgtLoginId, fxgt)
const linkHfm = () => link('hfm', toast, linkingHfm, hfmStore, member, hfmClientId, hfm)
const linkXm = () => link('xm', toast, linkingXm, xmStore, member, xmLoginId, xm)

const sendMessage = () => {
  sendingMessage.value = true

  memberStore.sendMessage(member.id, message.value).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})

    message.value = ''
    openSendMessage.value = false
    sendingMessage.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

const unlinkAxi = () => unlink('axi', toast, confirm, unlinkingAxi, axiStore, member, axi)
const unlinkBanAxi = (axiBanId) => unlinkBan('axi', toast, confirm, unlinkingAxi, configStore, member, axi, axiBanId)

const unlinkExness = () => unlink('exness', toast, confirm, unlinkingExness, exnessStore, member, exness)
const unlinkBanExness = (exnessBanId) => unlinkBan('exness', toast, confirm, unlinkingExness, configStore, member, exness, exnessBanId)

const unlinkFxgt = () => unlink('fxgt', toast, confirm, unlinkingFxgt, fxgtStore, member, fxgt)
const unlinkBanFxgt = (fxgtBanId) => unlinkBan('fxgt', toast, confirm, unlinkingFxgt, configStore, member, fxgt, fxgtBanId)

const unlinkHfm = () => unlink('hfm', toast, confirm, unlinkingHfm, hfmStore, member, hfm)
const unlinkBanHfm = (hfmBanId) => unlinkBan('hfm', toast, confirm, unlinkingHfm, configStore, member, hfm, hfmBanId)

const unlinkXm = () => unlink('xm', toast, confirm, unlinkingXm, xmStore, member, xm)
const unlinkBanXm = (xmBanId) => unlinkBan('xm', toast, confirm, unlinkingXm, configStore, member, xm, xmBanId)

const update = () => {
  loading.value = true
  const request = {
    comment: member.comment,
  }

  memberStore.update(member.id, request).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: data.message, life: 3000})
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  memberStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    for (let key in data.member) {
      if (key == 'active') {
        member[key] = data.member[key] ? true : false

        continue
      }
      member[key] = data.member[key]
    }

    activities.value = data.member.activities
    activities.value.sort((a,b) => a.id - b.id)

    axi.value = data.member.axi || { _: false }
    exness.value = data.member.exnesses[0] || { _: false }
    fxgt.value = data.member.fxgt || { _: false }
    hfm.value = data.member.hfms[0] || { _: false }
    xm.value = data.member.xms[0] || { _: false }

    initial.value = true
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
      <span class="block-title">
        <div>Show #{{ member.id }}</div>
      </span>

      <div class="flex align-items-center justify-content-center">
        <UtilityButtonBack />
      </div>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex flex-wrap">
        <div class="col-12 sm:col-6">

					<div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
						<ComponentTagMember :member="member" />
					</div>

          <div v-if="authStore.hasPermission('accounts')" class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
						<ComponentIB :member="member" />
          </div>

					<div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <ComponentLevel :member="member" />
					</div>

          <div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <div class="text-center mb-2">
              <img :src="member.cover_image" class="w-full" />
            </div>

            <div class="text-center mb-2">
              <img class="border-circle" :src="member.avatar" style="width: 100px;">
            </div>

            <div>
              <label class="block text-900 font-medium mb-2" for="commission_the_month_all_provider">
								Level :
								<span class="ml-1" />
								<MemberLevelTag :level="member.commission_the_month_all_provider" />
							</label>
              <InputText v-model="member.commission_the_month_all_provider" id="commission_the_month_all_provider" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="uid">UID</label>
              <InputText v-model="member.uid" id="uid" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="uhas_uid">Uhas UID</label>
              <InputText v-model="member.uhas_uid" id="uhas_uid" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="line_token">Line Token</label>
              <Dialog header="Message" v-model:visible="openSendMessage" :style="{width: '20vw'}" :modal="true">
                <InputText type="text" v-model="message" class="w-full" />
                <template #footer>
                  <Button
                    class="p-button-text"
                    icon="pi pi-times"
                    label="Cancel"
                    :loading="sendingMessage"

                    @click="openSendMessage = false"
                  />

                  <Button
                    autofocus
                    :disabled="!message"
                    icon="pi pi-check"
                    label="Send"
                    :loading="sendingMessage"

                    @click="sendMessage"
                  />
                </template>
              </Dialog>
              <div class="p-inputgroup mb-3">
                <Button v-if="member.line_token" icon="pi pi-comment" @click="openSendMessage = true" />
                <InputText v-model="member.line_token" id="line_token" type="text" readonly />
              </div>

              <a :href="`tel:${member.phone}`" ref="telToPhone" style="display:none;">{{ member.phone }}</a>
              <label class="block text-900 font-medium mb-2" for="phone">Phone</label>
              <div class="p-inputgroup mb-3">
                <Button icon="pi pi-phone" @click="$refs.telToPhone.click()" />
                <InputText v-model="member.phone" id="phone" readonly />
              </div>

              <label class="block text-900 font-medium mb-2" for="email">Email</label>
              <InputText v-model="member.email" id="email" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="birthday">Birthday</label>
              <InputText v-model="member.birthday" id="birthday" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="first_name">First Name</label>
              <InputText v-model="member.first_name" id="first_name" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="last_name">Last Name</label>
              <InputText v-model="member.last_name" id="last_name" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="date_of_birth">Birthday</label>
              <InputText v-model="member.date_of_birth" id="date_of_birth" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="province_id">Province</label>
              <InputText id="province_id" type="text" class="w-full mb-3" :value="provinces.find(x => x.id == member.province_id)?.name" readonly />

              <label class="block text-900 font-medium mb-2" for="district_id">District</label>
              <InputText id="district_id" type="text" class="w-full mb-3" :value="districts.find(x => x.id == member.district_id)?.name" readonly />

              <label class="block text-900 font-medium mb-2" for="subdistrict_id">Subdistrict</label>
              <InputText id="subdistrict_id" type="text" class="w-full mb-3" :value="subdistricts.find(x => x.id == member.subdistrict_id)?.name" readonly />

              <label class="block text-900 font-medium mb-2" for="zip_code">ZIP Code</label>
              <InputText v-model="member.zip_code" id="zip_code" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="address">Address</label>
              <InputText v-model="member.address" id="address" type="text" class="w-full mb-3" readonly />

              <label class="block text-900 font-medium mb-2" for="active">Active</label>
              <InputText v-model="member.active" id="active" type="text" class="w-full mb-3" readonly />

            </div>
          </div>

          <div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <div class="text-center mb-2">
              <DataTable
                :value="activities"
                :loading="! initial"
              >
                <template #empty>
                  No records.
                </template>
                <template #loading>
                  Loading. Please wait.
                </template>

                <Column header="Heading">
                  <template #body="slotProps">
                    <RouterLink :to="{ name: 'activities.show', params: { id: slotProps.data.id } }">
                      {{ slotProps.data.heading }}
                    </RouterLink>
                  </template>
                </Column>

                <Column field="pivot.server" header="Server" />
                <Column field="pivot.login" header="Login" />
                <Column field="pivot.password" header="Password" />

                <Column field="pivot.updated_at" header="Updated At" />
              </DataTable>
            </div>
          </div>
        </div>

        <div v-if="initial" class="col-12 sm:col-6">
          <div class="surface-card p-4 m-1 shadow-2 border-round w-full my-3">
            <div class="mb-5">
              <div class="text-4xl">Admin</div>
            </div>

            <label class="block text-900 font-medium mb-2" for="comment">Comment</label>
            <InputText v-model="member.comment" id="comment" type="text" class="w-full mb-3" />

            <br/>

            <Button label="Save" class="w-full" :loading="loading" @click="update" />
          </div>

          <MemberProviderConnection
            v-model="axiLogin"
            :authStore="authStore"
            :initing="axi._"
            @link="linkAxi"
            :linking="linkingAxi"
            permission="axis"
            subTitle="Login"
            title="Axi"
            @unlink="unlinkAxi"
            :unlinking="unlinkingAxi"
            @unlinkBan="unlinkBanAxi(axi.login)"
          >
            <template #accordion>
              <Accordion v-if="axi._ !== false && member.axi_same_names">
                <AccordionTab v-for="(axis_same_names, index) in member.axi_same_names" :key="axis_same_names.id" :header="`Axi Login : ${axis_same_names.login}`">
                  <FormAxi
                    v-model="member.axi_same_names[index]"
                  />
                </AccordionTab>
              </Accordion>
            </template>

            <template #form>
              <FormAxi
                v-model="axi"
              />
            </template>
          </MemberProviderConnection>

          <MemberProviderConnection
            v-model="exnessClientAccount"
            :authStore="authStore"
            :initing="exness._"
            @link="linkExness"
            :linking="linkingExness"
            permission="exnesses"
            subTitle="Client Account"
            title="Exness"
            @unlink="unlinkExness"
            :unlinking="unlinkingExness"
            @unlinkBan="unlinkBanExness(exness.client_account)"
          >
            <template #accordion>
              <Accordion v-if="exness._ !== false && member.exness_same_client_uids">
                <AccordionTab v-for="(exness_same_client_uid, index) in member.exness_same_client_uids" :key="exness_same_client_uid.id" :header="`Exness Client Account : ${exness_same_client_uid.client_account}`">
                  <FormExness
                    v-model="member.exness_same_client_uids[index]"
                  />
                </AccordionTab>
              </Accordion>
            </template>

            <template #form>
              <FormExness
                v-model="exness"
              />
            </template>
          </MemberProviderConnection>

          <MemberProviderConnection
            v-model="fxgtLoginId"
            :authStore="authStore"
            :initing="fxgt._"
            @link="linkFxgt"
            :linking="linkingFxgt"
            permission="fxgts"
            subTitle="Login ID"
            title="Fxgt"
            @unlink="unlinkFxgt"
            :unlinking="unlinkingFxgt"
            @unlinkBan="unlinkBanFxgt(fxgt.login_id)"
          >
            <template #accordion>
              <Accordion v-if="fxgt._ !== false && member.fxgt_same_client_ids">
                <AccordionTab v-for="(fxgt_same_client_id, index) in member.fxgt_same_client_ids" :key="fxgt_same_client_id.id" :header="`Fxgt Login ID : ${fxgt_same_client_id.login_id}`">
                  <FormFxgt
                    v-model="member.fxgt_same_client_ids[index]"
                  />
                </AccordionTab>
              </Accordion>
            </template>

            <template #form>
              <FormFxgt
                v-model="fxgt"
              />
            </template>
          </MemberProviderConnection>

          <MemberProviderConnection
            v-model="hfmClientId"
            :authStore="authStore"
            :initing="hfm._"
            @link="linkHfm"
            :linking="linkingHfm"
            permission="hfms"
            subTitle="Client ID"
            title="Hfm"
            @unlink="unlinkHfm"
            :unlinking="unlinkingHfm"
            @unlinkBan="unlinkBanHfm(hfm.from_hfm_id)"
          >
            <template #accordion>
              <Accordion v-if="hfm._ !== false && member.hfm_same_names">
                <AccordionTab v-for="(hfm_same_name, index) in member.hfm_same_names" :key="hfm_same_name.id" :header="`HFM ID : ${hfm_same_name.from_hfm_id}`">
                  <FormHfm
                    v-model="member.hfm_same_names[index]"
                  />
                </AccordionTab>
              </Accordion>
            </template>

            <template #form>
              <FormHfm
                v-model="hfm"
              />
            </template>
          </MemberProviderConnection>

          <MemberProviderConnection
            v-model="xmLoginId"
            :authStore="authStore"
            :initing="xm._"
            @link="linkXm"
            :linking="linkingXm"
            permission="xms"
            subTitle="Login ID"
            title="Xm"
            @unlink="unlinkXm"
            :unlinking="unlinkingXm"
            @unlinkBan="unlinkBanXm(xm.login_id)"
          >
            <template #accordion>
              <Accordion v-if="xm._ !== false && member.xm_same_client_ids">
                <AccordionTab v-for="(xm_same_client_id, index) in member.xm_same_client_ids" :key="xm_same_client_id.id" :header="`XM ID : ${xm_same_client_id.login_id}`">
                  <FormXm
                    v-model="member.xm_same_client_ids[index]"
                  />
                </AccordionTab>
              </Accordion>
            </template>

            <template #form>
              <FormXm
                v-model="xm"
              />
            </template>
          </MemberProviderConnection>

          <member-course-expire v-if="initial && authStore.hasPermission('courses')" :member="member" />
        </div>

      </div>
    </div>
  </div>
</template>
